import { Section } from "../../../../packages/editing/Section"
import { useQueryString } from "../../../../reactor/Web"
import { GetPhoneOrderDto, postRenewal, usePhoneOrder, useRenewalOptions } from "../client"
import { Heading } from "../ui/components/typography/Heading"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Uuid } from "../../../../reactor/Types/Primitives/Uuid"
import moment from "moment"
import { Button } from "../ui/components/buttons/Button"

Section(OrderView)
function OrderView(section: {}) {
    const orderId = useQueryString("id")[0] as Uuid<"PhoneOrder"> | undefined
    const order = usePhoneOrder((orderId as any) ?? null)
    const localize = useLocalize()

    if (!order.data) return <></>
    const { model } = order.data

    return (
        <div style={{ backgroundColor: "#fee", padding: 32 }}>
            <Heading level={2}>{localize(model.name)}</Heading>
            <OrderSummary order={order.data} />
            {orderId && <OrderRenewalOptions order={order.data} />}
            {orderId && <OrderReceipts order={order.data} />}
            <Button href={`/order-cancel?id=${orderId}`} variant="dark">
                Cancel subscription
            </Button>
        </div>
    )
}

function OrderSummary({ order }: { order: GetPhoneOrderDto }) {
    const price = order.rentalPeriod.monthlyPrice
    const currency = price.total.currency
    return (
        <div>
            <div>Next charge date: {moment(order.nextChargeDate).format("dddd MMMM Do YYYY")}</div>
            <div>Remaining months: {order.monthsLeft}</div>
            <div>Rental period: {order.rentalPeriod.months}</div>
            <div>
                Monthly price: {price.total.majorUnits} {currency}
                <ul>
                    <li>
                        Phone rent: {price.phone.majorUnits} {currency}
                    </li>
                    {price?.insurance && (
                        <li>
                            Insurance: {price.insurance.majorUnits} {currency}
                        </li>
                    )}
                    {price?.tradeInDiscount && (
                        <li>
                            Trade-in discount: {price.tradeInDiscount.majorUnits} {currency}
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

function OrderReceipts({ order }: { order: GetPhoneOrderDto }) {
    const price = order.rentalPeriod.monthlyPrice

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Your receipts
            </Heading>

            {order.receipts.map((receipt) => (
                <a
                    href={`/api/phone-orders/${order.id}/pdf-receipts/${receipt.id}`}
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        maxWidth: 400,
                        borderTop: "1px solid #ddd",
                    }}
                >
                    <div style={{ padding: 16 }}>{receipt.date}</div>
                    <div style={{ padding: 16 }}>
                        {receipt.total.majorUnits} {receipt.total.currency}
                    </div>
                </a>
            ))}
        </>
    )
}

function OrderRenewalOptions({ order }: { order: GetPhoneOrderDto }) {
    const renewalOptions = useRenewalOptions(order.id)

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Renew your subscription
            </Heading>
            {renewalOptions.data?.options.length === 0 && <div>No renewal options available</div>}
            {renewalOptions.error && <div>{renewalOptions.error.detail}</div>}
            {renewalOptions.data?.options.map((option) => (
                <div
                    style={{
                        margin: 16,
                        border: "1px solid #ddd",
                        padding: 16,
                        borderRadius: 8,
                        cursor: "pointer",
                    }}
                    onClick={async () => {
                        if (!confirm("Are you sure you want to renew your subscription?")) return
                        try {
                            await postRenewal(order.id, option.months)
                            alert("Renewal successful")
                        } catch (e: any) {
                            alert("Renewal failed: " + e.detail)
                        }
                    }}
                >
                    <div>{option.months} months</div>
                    <div>{option.price.total.majorUnits.toFixed(2)} NOK / mo</div>
                </div>
            ))}
        </>
    )
}
