import { ChangeEvent, ReactNode, useCallback, useMemo, useRef, useState } from "react"
import { css } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Icon } from "../visual/Icon"
import { responsiveHeadingCss, Text } from "../typography/Text"
import { responsiveCss } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { xlScaleFactor } from "../../constants/sizes"
import { responsiveBodyCss } from "../typography/Body"
import { Color, colors } from "../../constants/colors"

type State = "error" | "completed"
type InternalState = "default" | "active" | "error" | "completed"

const borderColors: { [key in InternalState]: Color } = {
    default: "gray200",
    active: "gray500",
    error: "error",
    completed: "forest",
}

export function Select<V>(props: {
    value: V
    options: { value: V; text: string; disabled?: boolean }[]
    onChange?: (e: ChangeEvent<HTMLSelectElement>, val: V) => void
    renderValue?: (val: V) => ReactNode

    /**
     * Apply style of error or completed to the input field.
     */
    state?: State

    /**
     * Providing a placeholder adds an option that is listed first, selected if no value is
     * provided, and disabled to prevent it being available as a regular value.
     */
    placeholder?: string

    label?: string
}) {
    const selectRef = useRef<HTMLSelectElement>(null)
    const [isActive, setIsActive] = useState(false)
    const state = useMemo<InternalState>(() => {
        if (props.state) return props.state
        if (isActive) return "active"
        return "default"
    }, [props.state, isActive])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            props.onChange?.(e, e.target.value as V)
        },
        [props]
    )

    const handleFocus = useCallback(() => {
        setIsActive(true)
    }, [setIsActive])

    const handleBlur = useCallback(() => {
        setIsActive(false)
    }, [setIsActive])

    const renderPlaceholder = useMemo(() => {
        return props.placeholder && !props.value
    }, [props.placeholder, props.value])

    return (
        <div>
            {props.label ? (
                <Text variant="body" size="md" style={{ marginBottom: 16 * xlScaleFactor }}>
                    {props.label}
                </Text>
            ) : null}
            <Flex
                borderColor={borderColors[state]}
                borderWidth={2}
                borderRadius="sm"
                css={selectCss}
                alignItems="center"
                justifyContent="space-between"
            >
                <div css={valueTextCss} style={renderPlaceholder ? { color: colors.gray300 } : {}}>
                    {renderPlaceholder
                        ? props.placeholder
                        : props.renderValue?.(props.value) ??
                          props.options.find(
                              (opt) => valueToString(opt.value) === valueToString(props.value)
                          )?.text}
                </div>
                <select
                    tabIndex={0}
                    css={dropdownCss}
                    ref={selectRef}
                    value={renderPlaceholder ? props.placeholder : valueToString(props.value)}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    {props.placeholder ? (
                        <option disabled={true} value={props.placeholder}>
                            {props.placeholder}
                        </option>
                    ) : null}
                    {props.options.map((o) => (
                        <option
                            key={valueToString(o.value)}
                            value={valueToString(o.value)}
                            disabled={o.disabled ?? false}
                        >
                            {o.text}
                        </option>
                    ))}
                </select>
                <Icon icon="chevronDown" />
            </Flex>
        </div>
    )
}

const valueTextCss = css(
    responsiveHeadingCss("5"),
    responsiveCss("min", "md", responsiveBodyCss("md") ?? {})
)

const selectCss = css`
    position: relative;
    height: ${48 * xlScaleFactor}px;
    padding: ${12 * xlScaleFactor}px ${20 * xlScaleFactor}px;
`

const dropdownCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minWidth: 0,
    appearance: "none",
    opacity: 0,
    cursor: "pointer",
})

Component(Select, {
    name: "Select",
    gallery: {
        path: "Inputs/Select",
        items: [
            {
                variants: [
                    {
                        props: {
                            value: "foo",
                            options: [
                                { value: "foo", text: "Foo" },
                                { value: "bar", text: "Bar" },
                            ],
                        },
                    },
                ],
            },
            {
                variants: [
                    {
                        props: {
                            value: "",
                            placeholder: "Select an option",
                            options: [
                                { value: "foo", text: "Foo" },
                                { value: "bar", text: "Bar" },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})

function valueToString(o: any): string {
    return typeof o === "string" ? o : JSON.stringify(o)
}
