import { Section } from "../../../../packages/editing/Section"
import { useQueryString } from "../../../../reactor/Web"
import { cancelSubscription, useCancellationTerms, usePhoneOrder } from "../client"
import { Heading } from "../ui/components/typography/Heading"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Uuid } from "../../../../reactor/Types/Primitives/Uuid"
import ReactMarkdown from "react-markdown"
import { Localized } from "../../../../packages/localization/Localized"
import { Checkbox } from "../ui/components/controllers/Checkbox"
import { useState } from "react"
import { Button } from "../ui/components/buttons/Button"

Section(CancelView)
function CancelView(section: {
    cancelFee: Localized<string>
    iAcceptTheTerms: Localized<string>
    confirmButton: Localized<string>
    /**
     * Displayed in the dialog box after a successful cancellation.
     */
    confirmText: Localized<string>
}) {
    const orderId = useQueryString("id")[0] as Uuid<"PhoneOrder"> | undefined
    const order = usePhoneOrder(orderId ?? null)
    const cancelTerms = useCancellationTerms(orderId ?? null)
    const [accepted, setAccepted] = useState(false)

    const localize = useLocalize()

    if (!order.data) return <></>
    const { model } = order.data

    async function cancel() {
        if (!orderId) {
            alert("No order ID")
            return
        }
        if (!cancelTerms.data) {
            alert("No cancel terms")
            return
        }

        await cancelSubscription(orderId, {
            cancellationFee: cancelTerms.data.cancellationFee,
            cancellationTerms: cancelTerms.data.cancellationTerms,
        })

        alert(localize(section.confirmText))
        location.pathname = "/account"
    }

    return (
        <div style={{ backgroundColor: "#fee", padding: 32 }}>
            <Heading level={2}>Cancel your subscription - {localize(model.name)}</Heading>

            <div>
                {cancelTerms.data && (
                    <ReactMarkdown>
                        {localize(cancelTerms.data?.cancellationTerms).valueOf()}
                    </ReactMarkdown>
                )}
            </div>
            <div>
                {localize(section.cancelFee)}: {cancelTerms.data?.cancellationFee.majorUnits}{" "}
                {cancelTerms.data?.cancellationFee.currency}
            </div>
            <div>
                <Checkbox
                    disabled={!cancelTerms.data}
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                />
                <div>{localize(section.iAcceptTheTerms)}</div>
            </div>
            <Button disabled={!cancelTerms.data && accepted} onClick={cancel}>
                {localize(section.confirmButton)}
            </Button>
        </div>
    )
}
