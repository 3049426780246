import { CSSProperties, ForwardedRef, forwardRef } from "react"
import { jsx } from "@emotion/react"
import { css, ResponsiveStyleValue, responsiveValueCss } from "../../helpers/css"
import { Box, BoxProps } from "./Box"

type SupportedElements = "div" | "a" | "label" | "nav" | "section"

type FlexProps<T extends SupportedElements = "div"> = BoxProps<T> & {
    direction?: CSSProperties["flexDirection"]
    flex?: CSSProperties["flex"]
    basis?: CSSProperties["flexBasis"]
    grow?: CSSProperties["flexGrow"]
    shrink?: CSSProperties["flexShrink"]
    alignItems?: CSSProperties["alignItems"]
    justifyContent?: CSSProperties["justifyContent"]
    alignSelf?: CSSProperties["alignSelf"]
    gap?: ResponsiveStyleValue
}

export const Flex = forwardRef(function Flex<T extends SupportedElements = "div">(
    {
        direction,
        flex,
        basis,
        grow,
        shrink,
        alignItems,
        justifyContent,
        alignSelf,
        gap,
        children,
        ...rest
    }: FlexProps<T>,
    ref: ForwardedRef<HTMLElement>
) {
    const boxProps = rest as BoxProps

    return jsx(Box, {
        ...boxProps,
        ref,
        css: css(
            {
                display: "flex",
                flex,
                flexBasis: basis,
                flexGrow: grow,
                flexShrink: shrink,
                flexDirection: direction,
                alignItems,
                justifyContent,
                alignSelf,
            },
            gap && responsiveValueCss("gap", gap)
        ),
        children,
    })
})
