import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { FileToUrl, Image, ImageToUrl } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { Flex } from "../base/Flex"
import { motion } from "framer-motion"
import { createRef, RefObject, useCallback, useEffect, useRef, useState } from "react"
import { colors } from "../../constants/colors"

export function ProductCarousel(props: { images: Image[] }) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const imageRefs = useRef<RefObject<HTMLDivElement>[]>(props.images.map(() => createRef()))

    const handleIntersectionChange: IntersectionObserverCallback = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const intersectingEntry = entries.find((entry) => entry.isIntersecting)
            if (!intersectingEntry) return

            const index = imageRefs.current.findIndex(
                (ir) => ir.current === intersectingEntry.target
            )
            if (index === -1) return
            setCurrentIndex(index)
        },
        [imageRefs, setCurrentIndex]
    )

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersectionChange, {
            root: containerRef.current,
            // Reduce threshold to fire a little before the scroll is ended.
            threshold: 0.8,
        })

        imageRefs.current.forEach((imageRef) => {
            if (imageRef.current) observer.observe(imageRef.current)
        })

        return () => {
            observer.disconnect()
        }
    }, [handleIntersectionChange, containerRef, imageRefs])

    return (
        <div ref={containerRef} style={{ position: "relative" }}>
            <Flex
                backgroundColor="gray100"
                borderRadius="md"
                padding={{ top: 40, bottom: props.images.length > 1 ? 64 : 40 }}
                style={{
                    overflowX: "scroll",
                    scrollSnapType: "x mandatory",
                    scrollbarWidth: "none",
                }}
                css={css`
                    &::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                {props.images.map((image, index) => (
                    <div
                        key={index}
                        ref={imageRefs.current[index]}
                        style={{
                            display: "flex",
                            flex: "1 0 100%",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            scrollSnapAlign: "center",
                        }}
                    >
                        <img
                            draggable="false"
                            src={ImageToUrl(image, { width: 400 })}
                            style={{
                                marginLeft: 40,
                                marginRight: 40,
                                width: "100%",
                                maxWidth: 200,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />
                    </div>
                ))}
                {props.images.length > 1 && (
                    <Flex
                        style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                        padding={12}
                        alignItems="center"
                        justifyContent="center"
                        gap={4}
                    >
                        {props.images.map((image, index) => (
                            <motion.div
                                key={index}
                                animate={{
                                    width: index === currentIndex ? 16 : 8,
                                    backgroundColor:
                                        index === currentIndex
                                            ? colors.gray500
                                            : `${colors.gray500}66`,
                                }}
                                css={css({
                                    height: 8,
                                    borderRadius: 4,
                                    overflow: "hidden",
                                })}
                            ></motion.div>
                        ))}
                    </Flex>
                )}
            </Flex>
        </div>
    )
}

Component(ProductCarousel, {
    name: "ProductCarousel",
    gallery: {
        path: "Carousel/Product",
        items: [
            {
                variants: [
                    {
                        props: {
                            images: [
                                `${server()}/static/redoit/product-card-product-image3.png` as any as Image,
                                `${server()}/static/redoit/product-card-product-image4.png` as any as Image,
                            ],
                        },
                        render: (cmp) => <div style={{ width: 600 }}>{cmp}</div>,
                    },
                ],
            },
        ],
    },
})
