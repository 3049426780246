import { Section } from "../../../../packages/editing/Section"
import { useNavigate } from "../../../../packages/hooks/useNavigate"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { useLogOutWithVipps } from "../../../../packages/oidc/code-flow/VippsLogin"
import { useMe, usePhoneOrders, useStoredPaymentMethods } from "../client"
import { Button } from "../ui/components/buttons/Button"
import { Heading } from "../ui/components/typography/Heading"

Section(AccountView)
function AccountView(section: {}) {
    const me = useMe()

    return (
        <div>
            <Heading level={2} margin={{ top: 32 }}>
                Welcome {me.data?.givenName}{" "}
            </Heading>
            <MyOrders />
            <MyPaymentMethods />
        </div>
    )
}

function MyOrders() {
    const orders = usePhoneOrders()
    const localize = useLocalize()
    const navigate = useNavigate()

    const logOutWithVipps = useLogOutWithVipps()

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Your orders
            </Heading>
            <Button
                variant="dark"
                onClick={async () => {
                    logOutWithVipps()
                    window.location.href = "/"
                }}
            >
                Log out
            </Button>
            {orders.data?.orders.map((order) => (
                <div
                    onClick={() => navigate(`/order?id=${order.id}`)}
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        maxWidth: 400,
                        borderTop: "1px solid #ddd",
                    }}
                >
                    <div style={{ padding: 16 }}>{localize(order.model.name)}</div>
                </div>
            ))}
        </>
    )
}

function MyPaymentMethods() {
    const paymentOptions = useStoredPaymentMethods()
    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                Your payment methods
            </Heading>
            {paymentOptions.data?.map((paymentOption) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        maxWidth: 400,

                        borderTop: "1px solid #ddd",
                    }}
                >
                    <div style={{ padding: 16 }}>
                        {paymentOption.brand === "visa" ? <Visa /> : paymentOption.brand}
                    </div>
                    <div style={{ padding: 16, flex: 1 }}>
                        **** **** **** {paymentOption.lastFour}
                    </div>
                    <div style={{ padding: 16 }}>
                        {paymentOption.expiryMonth}/{paymentOption.expiryYear}
                    </div>
                </div>
            ))}
        </>
    )
}

function Visa() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 324.68" width={48} height={32}>
            <path
                fill="#1434cb"
                d="m651.19.5c-70.93,0-134.32,36.77-134.32,104.69,0,77.9,112.42,83.28,112.42,122.42,0,16.48-18.88,31.23-51.14,31.23-45.77,0-79.98-20.61-79.98-20.61l-14.64,68.55s39.41,17.41,91.73,17.41c77.55,0,138.58-38.57,138.58-107.66,0-82.32-112.89-87.54-112.89-123.86,0-12.91,15.5-27.05,47.66-27.05,36.29,0,65.89,14.99,65.89,14.99l14.33-66.2S696.61.5,651.18.5h0ZM2.22,5.5L.5,15.49s29.84,5.46,56.72,16.36c34.61,12.49,37.07,19.77,42.9,42.35l63.51,244.83h85.14L379.93,5.5h-84.94l-84.28,213.17-34.39-180.7c-3.15-20.68-19.13-32.48-38.68-32.48,0,0-135.41,0-135.41,0Zm411.87,0l-66.63,313.53h81L494.85,5.5h-80.76Zm451.76,0c-19.53,0-29.88,10.46-37.47,28.73l-118.67,284.8h84.94l16.43-47.47h103.48l9.99,47.47h74.95L934.12,5.5h-68.27Zm11.05,84.71l25.18,117.65h-67.45l42.28-117.65h0Z"
            />
        </svg>
    )
}
