import { useEffect, useState } from "react"
import { css as emotion } from "@emotion/react"
import { CSSInterpolation, CSSObject, SerializedStyles } from "@emotion/serialize"

import {
    BodySize,
    BorderRadiusVariant,
    ButtonSize,
    HeadingSize,
    ScreenSize,
    Size,
    SpacingSize,
    bodySizes,
    borderRadiusVariants,
    buttonSizes,
    headingSizes,
    lgScaleFactor,
    mdScaleFactor,
    screenSizes,
    sectionSpacing,
    spacingSizes,
    xlScaleFactor,
    xsScaleFactor,
} from "../constants/sizes"
import { CSSProperties } from "react"
import { colors } from "../constants/colors"

export function css(template: TemplateStringsArray, ...args: CSSInterpolation[]): SerializedStyles
export function css(...args: CSSInterpolation[]): SerializedStyles
export function css(...args: any[]): SerializedStyles {
    return emotion(...args)
}

const limitTypes = ["min", "max"] as const
type LimitType = (typeof limitTypes)[number]

/**
 * A media query.
 */
export type MediaQuery = [LimitType, ScreenSize]

/**
 * A media query for when to apply variant V.
 */
export type VariantMediaQuery<V extends string> = [LimitType, ScreenSize, V]

// Helper types for props or functions that supports values with optional media queries.

/**
 * Type helper for responsive properties that takes variant values. Accepts both a single
 * variant which is sufficient for most use cases, but also an array with a base variant for
 * smallest screen size, and media queries for when to apply other variants.
 * E.g. `['sm', ['min', 'md', 'xs']]` which would render the small variant on the smallest screens
 * and then switch to the xs variant from screen size md.
 */
export type ResponsiveVariant<V extends string> =
    | V
    | [V, ...VariantMediaQuery<V>[]]
    | VariantMediaQuery<V>[]

/**
 * Type helper for fixed variant styles for a given screen size. E.g. `['fixed', 'sm', 'lg']`
 * would render the sm screen size version of the lg variant on _all screen sizes_.
 */
export type FixedVariant<V extends string> = ["fixed", ScreenSize, V]

/**
 * A fixed or responsive value, usually in combination with a style property.
 * E.g. `borderWidth: 1, ['min', 'md', 2]` which means base border width should be 1,
 * and then for md size screens and up it should be 2.
 */
export type ResponsiveStyleValue<V = number | string> =
    | V
    | [V, ...[LimitType, ScreenSize, V][]]
    | [LimitType, ScreenSize, V][]

// Types for records of responsive styles, variants and values.

/**
 * Record of styles for one or more screen sizes.
 */
export type ResponsiveStyles = Partial<Record<ScreenSize, CSSObject>>

/**
 * A set of styles for responsive variants, headings or body text styles, where each size is
 * considered a variant.
 */
export type ResponsiveVariantStyles<V extends string> = Partial<Record<V, ResponsiveStyles>>

/**
 * A set of values for different screen sizes.
 */
export type ResponsiveStyleValues = Record<Size, Record<ScreenSize, number>>

// Types for records of non responsive values and variants like colors and button variants.

/**
 * Record of styles for one or more variants. A variant can be buttons of different styles or
 * a heading or body text size.
 */
export type VariantStyles<V extends string> = Record<V, CSSObject>

/**
 * A record of keys and style values, e.g. colors.
 */
export type KeyValues<K extends string> = Record<K, string | number>

/**
 * Helper function that returns a media query for a given screen size and limit type.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @returns A media query string
 */
export function mediaQuery(limitType: LimitType, screenSize: ScreenSize): string {
    switch (limitType) {
        case "min":
            return `@media (min-width: ${screenSizes[screenSize]}px)`
        case "max": {
            const sizesKeysArr = Object.keys(screenSizes) as ScreenSize[]
            const sizeIndex = sizesKeysArr.indexOf(screenSize)

            if (sizeIndex + 1 === sizesKeysArr.length) {
                return "@media"
            }

            const nextSize = sizesKeysArr[sizeIndex + 1]

            if (!nextSize) {
                return "@media"
            }

            return `@media (max-width: ${screenSizes[nextSize] - 1}px)`
        }
        default:
            return ""
    }
}

/**
 * A hook that returns a boolean indicating whether the screen matches or not.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @returns A media query string
 */
export function useMediaQuery(limitType: LimitType, screenSize: ScreenSize) {
    const [matches, setMatches] = useState(false)
    const query = mediaQuery(limitType, screenSize).replace("@media", "")

    useEffect(() => {
        const media = window.matchMedia(query)
        if (media.matches !== matches) {
            setMatches(media.matches)
        }

        const listener = () => {
            setMatches(media.matches)
        }

        media.addEventListener("change", listener)

        return () => {
            media.removeEventListener("change", listener)
        }
    }, [matches, query])

    return matches
}

/**
 * A base reset that should be applied to all elements that is reset.
 */
const baseReset = css({
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: "100%",
    font: "inherit",
    verticalAlign: "baseline",
})

/**
 * Helper function that returns reset styles for a given HTML element, beyond the base reset
 * styles, but also removes default styles.
 *
 * @param element The HTML element to return reset styles for
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function resetStyles(element: keyof JSX.IntrinsicElements): SerializedStyles {
    switch (element) {
        case "button":
            return css(
                baseReset,
                css({
                    background: "none",
                    border: "none",
                    padding: 0,
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                })
            )
        case "input":
            return css(baseReset, {
                border: "none",
                background: "none",
                font: "inherit",
                outline: "none",
                webkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
            })
        case "ol":
        case "ul":
            return css(baseReset, css({ listStyle: "none" }))
        default:
            return baseReset
    }
}

export const underline = css({
    textDecoration: "underline",
    textDecorationSkipInk: "none",
    textUnderlineOffset: "20%",
})

/**
 * Global styles that apply to the whole site. More or less just reset + fonts and then some.
 */
export const globalStyles = css(
    {
        html: {
            boxSizing: "border-box",
        },
        "*, *:before, *:after": {
            boxSizing: "inherit",
            margin: 0,
            padding: 0,
            border: 0,
            fontSize: "100%",
            font: "inherit",
            verticalAlign: "baseline",
        },
        button: {
            background: "none",
            border: "none",
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
        },
        body: {
            fontFamily: "Catalogue, sans-serif",
            color: colors.gray500,
            fontSize: 18,
            "--scale-factor": xsScaleFactor,
        },
        "p a": {
            textDecoration: "underline",
            textDecorationSkipInk: "none",
            textUnderlineOffset: "20%",
        },
        "p:last-child": {
            marginBottom: 0,
        },
        strong: {
            fontWeight: 500,
        },
        em: {
            fontStyle: "italic",
        },
        a: { textDecoration: "none", color: "inherit" },
    },
    responsiveCss("min", "md", {
        body: {
            "--scale-factor": mdScaleFactor,
        },
    }),
    responsiveCss("min", "lg", {
        body: {
            "--scale-factor": lgScaleFactor,
        },
    }),
    responsiveCss("min", "xl", {
        body: {
            "--scale-factor": xlScaleFactor,
        },
    }),
    retinaCss({
        body: {
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            fontSmooth: "never",
        },
    })
)

export function retinaCss(styles: CSSObject): SerializedStyles {
    const mq = `@media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx)
    `
    return css({
        [mq]: styles,
    })
}

export function touchCss(styles: CSSObject): SerializedStyles {
    return css({
        "@media (hover: none)": styles,
    })
}

/**
 * Helper function that returns a media query and styles for a given screen size.
 *
 * @param limitType The type of limit to apply
 * @param screenSize The screen size to apply the styles to
 * @param styles The styles to apply to the given screen size limit
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveCss(
    limitType: LimitType,
    screenSize: ScreenSize,
    styles: CSSObject | SerializedStyles
): SerializedStyles {
    return css({ [mediaQuery(limitType, screenSize)]: styles })
}

function isVariant<V extends string>(
    variants: VariantStyles<V>,
    variant: VariantMediaQuery<V> | ResponsiveVariant<V>
): variant is V {
    return Object.keys(variants).includes(variant as any)
}

function isVariantMediaQuery<T extends string>(
    variant: VariantMediaQuery<T> | ResponsiveVariant<T>
): variant is VariantMediaQuery<T> {
    return (
        variant instanceof Array &&
        variant.length === 3 &&
        typeof variant[0] === "string" &&
        limitTypes.includes(variant[0] as any) &&
        typeof variant[1] === "string" &&
        typeof variant[2] === "string"
    )
}

/**
 * Returns responsive CSS for a responsive variant that does not have a responsive dimension.
 */
export function variantCss<T extends string>(
    variants: VariantStyles<T>,
    variant: VariantMediaQuery<T> | ResponsiveVariant<T>
): SerializedStyles | undefined {
    if (isVariant(variants, variant) || typeof variant === "string") {
        return css(variants[variant])
    } else if (isVariantMediaQuery(variant)) {
        const variantMediaQuery = variant
        const [limitType, screenSize, v] = variantMediaQuery
        const style = variants[v]
        if (style) {
            return css(responsiveCss(limitType, screenSize, style))
        }
    } else if (variant instanceof Array) {
        return css(variant.map((v) => variantCss<T>(variants, v)))
    }

    return
}

// Helper function to get the screen sizes based on min/max and the provided size
function getScreenSizes(limitType: LimitType, screenSize: ScreenSize): ScreenSize[] {
    const order = Object.keys(screenSizes)
    const index = order.indexOf(screenSize)
    if (limitType === "min") {
        return order.slice(index) as ScreenSize[]
    } else {
        return order.slice(0, index + 1) as ScreenSize[]
    }
}

function getStyleForScreenSize(screenSize: ScreenSize, screenSizeStyles: ResponsiveStyles) {
    return getScreenSizes("max", screenSize).reduce((acc, curr) => {
        return Object.assign(acc, screenSizeStyles[curr] ?? {})
    }, {})
}

function getCssForScreenSize(screenSize: ScreenSize, screenSizeStyles: ResponsiveStyles) {
    const sss = getScreenSizes("max", screenSize)
    return css(sss.map((ss) => screenSizeStyles[ss]))
}

/**
 * Type guard for checking if a string is a screen size.
 */
function isScreenSize(screenSize: string): screenSize is ScreenSize {
    return Object.keys(screenSizes).includes(screenSize)
}

/**
 * Returns responsive CSS for a set of ResponsiveStyles.
 */
function responsiveVariantToCss(variant: ResponsiveStyles): SerializedStyles {
    return css(
        Object.entries(variant).map(([screenSize, styles]) =>
            isScreenSize(screenSize)
                ? responsiveCss("min", screenSize, {
                      ...styles,
                  })
                : undefined
        )
    )
}

function isMediaQuery<T extends string>(
    v: T | VariantMediaQuery<T> | VariantMediaQuery<T>[]
): v is VariantMediaQuery<T>[] {
    if (v instanceof Array === false) return false

    const [limitType, screenSize, variant] = v
    if (!limitTypes.includes(limitType as any)) return false
    if (!isScreenSize(screenSize as any)) return false
    if (typeof variant !== "string") return false

    return true
}

export function fixedVariantCss<T extends string>(
    variants: ResponsiveVariantStyles<T>,
    variant: FixedVariant<T>
) {
    const fixed = variant[0] === "fixed"

    if (fixed) {
        const baseScreenSize = Object.keys(screenSizes)[0] as ScreenSize
        const [, screenSize, variantKey] = variant
        if (
            !baseScreenSize ||
            typeof variantKey !== "string" ||
            typeof screenSize !== "string" ||
            !variants[variantKey]
        ) {
            return
        }

        return getCssForScreenSize(screenSize, variants[variantKey])
    }
}

/**
 * Returns responsive CSS for a responsive variant that has a responsive dimension.
 */
export function responsiveVariantsCss<T extends string>(
    variants: ResponsiveVariantStyles<T>,
    variant: ResponsiveVariant<T>
): SerializedStyles | undefined {
    if (typeof variant === "string" && variants[variant]) {
        return responsiveVariantToCss(variants[variant])
    } else if (variant instanceof Array) {
        const [baseVariant, ...rest] = variant.filter(
            (s) => typeof s === "string" && Object.keys(variants).includes(s)
        )

        // Specifying more than one variant without media query does not make sense.
        if (rest.length) {
            // eslint-disable-next-line no-console
            console.warn("Should not use more than one string.", variant)
        }

        const overrides = variant.filter((v) => isMediaQuery<T>(v))

        // Check if used a combination of min and max which is not advisable.
        if (overrides.find((o) => o[0] === "min") && overrides.find((o) => o[0] === "max")) {
            // eslint-disable-next-line no-console
            console.warn(
                "Combining min and max is not recommended. Try a base + min overrides instead for example."
            )
        }

        if (typeof baseVariant !== "string") return

        const baseSizeStyles = { ...variants[baseVariant] }

        if (!baseSizeStyles) return

        overrides.forEach((o) => {
            if (typeof o === "string") return
            const [limitType, screenSize, v] = o
            const screenSizesToOverride = getScreenSizes(limitType, screenSize)
            screenSizesToOverride.forEach((ss) => {
                const responsiveStyles = variants[v]
                if (!responsiveStyles) return
                baseSizeStyles[ss] = getStyleForScreenSize(ss, responsiveStyles)
            })
        })

        return css(
            Object.entries(baseSizeStyles as Record<ScreenSize, CSSProperties>).map(
                ([screenSize, styles]) =>
                    responsiveCss("min", screenSize as ScreenSize, {
                        ...styles,
                    })
            )
        )
    }

    return
}

export function responsivePropCss<V extends string>(
    values: KeyValues<V>,
    prop: string,
    responsiveValues: ResponsiveVariant<V>
): SerializedStyles {
    if (typeof responsiveValues === "string") {
        return css({ [prop]: values[responsiveValues] })
    }
    return css(
        responsiveValues.map((rv) => {
            if (typeof rv === "string") {
                return { [prop]: values[rv] }
            } else if (rv) {
                const [limitType, screenSize, value] = rv
                return responsiveCss(limitType, screenSize, { [prop]: values[value] })
            }
        })
    )
}

export function responsiveValueCss(
    prop: string,
    responsiveValues: ResponsiveStyleValue
): SerializedStyles {
    if (typeof responsiveValues === "string" || typeof responsiveValues === "number") {
        return css({ [prop]: responsiveValues })
    }
    return css(
        responsiveValues.map((rv) => {
            if (typeof rv === "string" || typeof rv === "number") {
                return { [prop]: rv }
            } else if (rv) {
                const [limitType, screenSize, value] = rv
                return responsiveCss(limitType, screenSize, { [prop]: value })
            }
        })
    )
}

/**
 * Helper function that returns a media query with responsive styles for a given border radius
 * variant for different screen sizes as specified in the border radius variants constant.
 *
 * @param variant The border radius variant
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveBorderRadius(variant: BorderRadiusVariant) {
    if (typeof borderRadiusVariants[variant] === "object") {
        return Object.entries(borderRadiusVariants[variant] as Record<ScreenSize, number>).map(
            ([screenSize, borderRadius]) =>
                responsiveCss("min", screenSize as ScreenSize, {
                    borderRadius: scaleValue(borderRadius),
                })
        )
    }

    return []
}

/**
 * Helper function that returns a media query with responsive CSS for a given heading size, based
 * on sizes defined in the `headingSizes` constant.
 *
 * @param variant The border radius variant
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveHeadingSize(variant: HeadingSize) {
    if (typeof headingSizes[variant] === "object") {
        return Object.entries(headingSizes[variant] as Record<ScreenSize, CSSProperties>).map(
            ([screenSize, headingStyle]) =>
                responsiveCss("min", screenSize as ScreenSize, { ...headingStyle })
        )
    }

    return []
}

/**
 * Helper function that returns a media query with responsive CSS for a given body size, based
 * on sizes defined in the `bodySizes` constant.
 *
 * @param variant The border radius variant
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveBodySize(variant: BodySize) {
    if (typeof bodySizes[variant] === "object") {
        return Object.entries(bodySizes[variant] as Record<ScreenSize, CSSProperties>).map(
            ([screenSize, bodyStyle]) =>
                responsiveCss("min", screenSize as ScreenSize, { ...bodyStyle })
        )
    }

    return []
}

/**
 * Helper function that returns a media query with responsive CSS for a given button size, based
 * on sizes defined in the `buttonSizes` constant.
 *
 * @param variant The border radius variant
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function responsiveButtonSize(variant: ButtonSize, iconOnly?: boolean) {
    if (typeof buttonSizes[variant] === "object") {
        return Object.entries(buttonSizes[variant] as Record<ScreenSize, CSSProperties>).map(
            ([screenSize, buttonStyle]) =>
                responsiveCss("min", screenSize as ScreenSize, {
                    ...buttonStyle,
                    ...(iconOnly
                        ? { width: buttonStyle.height, paddingLeft: 0, paddingRight: 0 }
                        : {}),
                })
        )
    }

    return []
}

export function responsiveSpacing(variant: SpacingSize, type: string | string[]) {
    if (typeof spacingSizes[variant] === "object") {
        return Object.entries(spacingSizes[variant] as Record<ScreenSize, number>).map(
            ([screenSize, val]) =>
                responsiveCss(
                    "min",
                    screenSize as ScreenSize,
                    type instanceof Array
                        ? Object.fromEntries(type.map((t) => [t, val]))
                        : { [type]: typeof val === "number" ? `${val}px` : val }
                )
        )
    }

    return []
}

export function responsiveBoxShadowVars(variableName: string) {
    // eslint-disable-next-line no-console
    if (!variableName.startsWith("--")) console.warn("CSS-variables should start with `--`.")

    return css(
        {
            [variableName]: "0px 2px 12px 0px rgba(21, 6, 36, 0.12)",
        },
        responsiveCss("min", "md", {
            [variableName]: "0px 3px 18px 0px rgba(21, 6, 36, 0.12)",
        }),
        responsiveCss("min", "lg", {
            [variableName]: "0px 4px 24px 0px rgba(21, 6, 36, 0.12)",
        }),
        responsiveCss("min", "xl", {
            [variableName]: "0px 4px 32px 0px rgba(21, 6, 36, 0.12)",
        })
    )
}

export function responsiveBoxShadow() {
    return css(
        {
            boxShadow: "0px 2px 12px 0px rgba(21, 6, 36, 0.12)",
        },
        responsiveCss("min", "md", {
            boxShadow: "0px 3px 18px 0px rgba(21, 6, 36, 0.12)",
        }),
        responsiveCss("min", "lg", {
            boxShadow: "0px 4px 24px 0px rgba(21, 6, 36, 0.12)",
        }),
        responsiveCss("min", "xl", {
            boxShadow: "0px 4px 32px 0px rgba(21, 6, 36, 0.12)",
        })
    )
}

export function scaleValue(value: number | string): string {
    return `calc(${typeof value === "number" ? `${value}px` : value} * var(--scale-factor))`
}

export function responsiveSectionSpacing() {
    return Object.entries(sectionSpacing as Partial<Record<ScreenSize, number | string>>).map(
        ([screenSize, spacing]) =>
            responsiveCss("min", screenSize as ScreenSize, {
                paddingTop: spacing,
                paddingBottom: spacing,
            })
    )
}

/**
 * Hide the element for screen sizes that match the provided media query.
 */
export function responsiveHidden(mq: MediaQuery) {
    return responsiveCss(...mq, { display: "none" })
}
