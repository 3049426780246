import { css } from "@emotion/react"
import { useState } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { Image, ImageToUrl } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { Box } from "../base/Box"
import { Flex } from "../base/Flex"
import { Heading } from "../typography/Heading"
import { Icon } from "../visual/Icon"
import { Text } from "../typography/Text"
import { responsiveCss } from "../../helpers/css"
import { Modal } from "../modal/Modal"

export function WhatsInTheBoxBlock(props: {
    compact?: boolean
    heading: string
    items: BoxItemProps[]
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    if (props.compact) {
        return (
            <>
                <Flex
                    gap={8}
                    alignItems="center"
                    borderRadius="md"
                    borderColor="gray200"
                    padding={16}
                    role="button"
                    onClick={() => setModalIsOpen(true)}
                    style={{ cursor: "pointer" }}
                >
                    <Icon icon="delivery" />
                    <Text variant="body" size="md" underline>
                        {props.heading}
                    </Text>
                </Flex>
                <Modal
                    isOpen={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    header={{ title: props.heading, closeButton: true, icon: "delivery" }}
                >
                    <BoxItems items={props.items} />
                </Modal>
            </>
        )
    }

    return (
        <Box borderRadius="md" borderColor="gray200" padding={24}>
            <Flex gap={8} margin={{ bottom: 24 }} alignItems="center">
                <Icon icon="delivery" />
                <Heading level={4}>{props.heading}</Heading>
            </Flex>
            <BoxItems items={props.items} />
        </Box>
    )
}

function BoxItems(props: { items: BoxItemProps[] }) {
    return (
        <div
            css={css(
                { display: "grid", gridTemplateColumns: "1fr", gap: 8 },
                responsiveCss("min", "lg", { gridTemplateColumns: "1fr 1fr" })
            )}
        >
            {props.items.map((item, index) => (
                <BoxItem key={`${index}${item.heading}`} {...item} />
            ))}
        </div>
    )
}

export type BoxItemProps = { image: Image; heading: string; text: string }
function BoxItem(props: BoxItemProps) {
    return (
        <Flex backgroundColor="gray100" borderRadius="sm" padding={24} gap={20} alignItems="center">
            <img src={ImageToUrl(props.image)} style={{ width: 32 }} />
            <div>
                <Text variant="body" size="md">
                    {props.heading}
                </Text>
                <Text variant="body" size="xs" color="gray400">
                    {props.text}
                </Text>
            </div>
        </Flex>
    )
}

Component(WhatsInTheBoxBlock, {
    name: "WhatsInTheBoxBlock",
    gallery: {
        path: "Blocks/WhatsInTheBoxBlock",
        items: [
            {
                variants: [
                    {
                        props: {
                            heading: "What’s in the box?",
                            items: [
                                {
                                    heading: "Phone",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-phone.svg` as any as Image,
                                },
                                {
                                    heading: "Booklet",
                                    text: "Pellentesque quis erat id enim elementum molestie quis sit amet neque",
                                    image: `${server()}/static/redoit/in-the-box-item-booklet.svg` as any as Image,
                                },
                                {
                                    heading: "Protective case",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-case.svg` as any as Image,
                                },
                                {
                                    heading: "Charging cable",
                                    text: "Donec faucibus fermentum turpis, vitae pulvinar tortor semper non",
                                    image: `${server()}/static/redoit/in-the-box-item-chargingcable.svg` as any as Image,
                                },
                                {
                                    heading: "Redoit gurantee",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-redoitguarantee.svg` as any as Image,
                                },
                                {
                                    heading: "Screen protector",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-screenprotector.svg` as any as Image,
                                },
                            ],
                        },
                    },
                ],
            },
            {
                variants: [
                    {
                        props: {
                            heading: "What’s in the box?",
                            compact: true,
                            items: [
                                {
                                    heading: "Phone",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-phone.svg` as any as Image,
                                },
                                {
                                    heading: "Booklet",
                                    text: "Pellentesque quis erat id enim elementum molestie quis sit amet neque",
                                    image: `${server()}/static/redoit/in-the-box-item-booklet.svg` as any as Image,
                                },
                                {
                                    heading: "Protective case",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-case.svg` as any as Image,
                                },
                                {
                                    heading: "Charging cable",
                                    text: "Donec faucibus fermentum turpis, vitae pulvinar tortor semper non",
                                    image: `${server()}/static/redoit/in-the-box-item-chargingcable.svg` as any as Image,
                                },
                                {
                                    heading: "Redoit gurantee",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-redoitguarantee.svg` as any as Image,
                                },
                                {
                                    heading: "Screen protector",
                                    text: "The phone you ordered",
                                    image: `${server()}/static/redoit/in-the-box-item-screenprotector.svg` as any as Image,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
