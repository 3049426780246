import { ChangeEvent, ReactNode } from "react"
import { RadioButton } from "../controllers/Radio"

type Option<V> = { value: V; text: string; disabled?: boolean }

export function RadioGroup<V>(props: {
    options: Option<V>[]
    renderOption: (value: Option<V>, selected: boolean) => ReactNode
    onChange: (e: ChangeEvent<HTMLInputElement>, val: V) => void
    value: V
}) {
    return (
        <>
            {props.options.map((o) => (
                <label key={JSON.stringify(o.value)} style={{ position: "relative" }}>
                    {props.renderOption(o, props.value === o.value)}
                    <input
                        type="radio"
                        checked={props.value === o.value}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            opacity: "0",
                            zIndex: -1,
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.onChange(e, o.value)
                        }}
                    />
                </label>
            ))}
        </>
    )
}
