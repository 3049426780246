import { motion } from "framer-motion"
import { Markdown, Uuid } from "../../../../../../reactor"
import { Flex } from "../base/Flex"
import { useCallback, useEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { FaqItem } from "./Faq"

export function FaqBlock(props: {
    /**
     * The heading for this block.
     */
    heading: string

    /**
     * Limit the number of questions initially displayed before clicking "Show all" button.
     */
    maxInitialQuestions?: number

    questions: { id: Uuid<"Question">; question: string; answer: Markdown }[]

    /**
     * Text for button to show all questions.
     */
    showAllButtonText?: string
}) {
    const [openQuestions, setOpenQuestions] = useState<Uuid<"Question">[]>([])
    const [showAll, setShowAll] = useState<boolean>(false)

    const handleClick = useCallback(
        (id: Uuid<"Question">) => () => {
            const alreadyOpen = openQuestions.includes(id)
            setOpenQuestions(
                alreadyOpen ? openQuestions.filter((q) => q !== id) : [...openQuestions, id]
            )
        },
        [openQuestions]
    )

    const containerRef = useRef<HTMLDivElement>(null)
    const [restQuestionsHeight, setRestQuestionsHeight] = useState<number | string>(0)

    const updateHeight = useCallback(() => {
        setRestQuestionsHeight(containerRef.current?.clientHeight || 0)
    }, [])

    useEffect(() => {
        if (showAll && containerRef.current?.clientHeight) {
            updateHeight()
        }
    }, [showAll, updateHeight])

    return (
        <Flex direction="column">
            <Flex
                alignItems="center"
                justifyContent="space-between"
                css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}
            >
                <Heading level={2}>{props.heading}</Heading>
                <Flex
                    shrink={0}
                    margin={{ left: 16 }}
                    css={responsiveCss("max", "xs", {
                        display: "none",
                    })}
                >
                    {typeof props.maxInitialQuestions === "number" &&
                    props.questions.length > props.maxInitialQuestions ? (
                        <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => setShowAll(true)}
                            disabled={showAll}
                        >
                            {props.showAllButtonText}
                        </Button>
                    ) : null}
                </Flex>
            </Flex>
            {props.questions
                .slice(0, props.maxInitialQuestions ?? props.questions.length)
                .map((q) => (
                    <div
                        key={q.id.valueOf()}
                        onClick={handleClick(q.id)}
                        css={css(
                            { marginBottom: 8, "&:last-of-type": { marginBottom: 0 } },
                            responsiveCss("min", "md", { marginBottom: scaleValue(16) })
                        )}
                    >
                        <FaqItem
                            question={q.question}
                            answer={q.answer}
                            isOpen={openQuestions.includes(q.id)}
                        />
                    </div>
                ))}
            {showAll && (
                <motion.div
                    initial={{ height: 0 }}
                    animate={{
                        height: restQuestionsHeight,
                    }}
                    onAnimationComplete={() => {
                        // After animation is done, set height to auto, to automatically get the
                        // correct height when opening questions or window is resized.
                        setRestQuestionsHeight("auto")
                        containerRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                        })
                    }}
                >
                    <div ref={containerRef} style={{ scrollMarginBottom: 24 }}>
                        {props.questions
                            .slice(props.maxInitialQuestions, props.questions.length)
                            .map((q, index) => (
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        transform: `translateY(-${index + 1 * 80}px)`,
                                    }}
                                    animate={{ opacity: 1, transform: "translateY(0px)" }}
                                    onAnimationComplete={() => {}}
                                    key={q.id.valueOf()}
                                    onClick={handleClick(q.id)}
                                    css={css(
                                        { marginBottom: 8, "&:last-of-type": { marginBottom: 0 } },
                                        responsiveCss("min", "md", { marginBottom: scaleValue(16) })
                                    )}
                                >
                                    <FaqItem
                                        question={q.question}
                                        answer={q.answer}
                                        isOpen={openQuestions.includes(q.id)}
                                    />
                                </motion.div>
                            ))}
                    </div>
                </motion.div>
            )}
            {typeof props.maxInitialQuestions === "number" &&
            props.questions.length > props.maxInitialQuestions ? (
                <Button
                    size="sm"
                    variant="dark"
                    onClick={() => setShowAll(true)}
                    disabled={showAll}
                    fullwidth
                    margin={{ top: 8 }}
                    css={responsiveCss("min", "sm", { display: "none" })}
                >
                    {props.showAllButtonText}
                </Button>
            ) : null}
        </Flex>
    )
}
