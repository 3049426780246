import { colors } from "../../constants/colors"
import { SpacingSize } from "../../constants/sizes"
import { spacingToCss } from "../base/Box"

export function Divider({
    horizontal,
    weight = 1,
    spacing = 8,
}: {
    /**
     * Spacing to sibling elements.
     */
    spacing?: number | SpacingSize

    /**
     * Specify a number if you need a heavier divider than 1px.
     */
    weight?: number
} & (
    | {
          /**
           * Display a horizontal divider.
           */
          horizontal: true
          vertical?: never
      }
    | {
          /**
           * Display a vertical divider.
           */
          vertical: true
          horizontal?: never
      }
)) {
    return (
        <div css={spacingToCss("padding", { [horizontal ? "y" : "x"]: spacing })}>
            <div
                style={{
                    [horizontal ? "height" : "width"]: weight,
                    backgroundColor: colors.gray200,
                    borderRadius: weight,
                }}
            />
        </div>
    )
}
