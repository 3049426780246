import { css } from "../helpers/css"
import { responsiveCss } from "../helpers/css"
import { Flex } from "./base/Flex"
import { Icon, IconName } from "./visual/Icon"
import { Text } from "./typography/Text"
import { Markdown } from "../../../../../reactor"
import { MarkdownView } from "../../../../../packages/markdown-edit/MarkdownView"

/**
 * Notification
 */
export function Notification(props: {
    /**
     * Show the notification across the whole screen width.
     */
    full?: boolean

    /**
     *  Callback for when the notification is closed by the user.
     */
    onClose?: () => void

    /**
     * An icon to show in combination with the text.
     */
    icon?: IconName

    /**
     * The message to show in the notification, to support both plain text and JSX.
     */
    text: Markdown | string
}) {
    return (
        <Flex
            backgroundColor="forest"
            color="grayWhite"
            alignItems="center"
            gap={8}
            style={
                props.full
                    ? {
                          width: "100vw",
                          marginLeft: "calc(50% - 50vw)",
                      }
                    : { borderRadius: 16, justifyContent: "flex-start" }
            }
            css={css(
                {
                    padding: `${props.full ? 20 : 16}px 16px`,
                },
                responsiveCss("min", "md", {
                    padding: props.full ? "12px 24px" : 16,
                })
            )}
        >
            {props.icon ? <Icon icon={props.icon} /> : null}
            <Flex
                grow={1}
                justifyContent={props.full ? "center" : "flex-start"}
                style={{
                    textAlign: "left",
                }}
            >
                <Text variant="body" size="md">
                    <MarkdownView
                        value={props.text}
                        renderers={{
                            paragraph: (p) => <div>{p.children}</div>,
                        }}
                    />
                </Text>
            </Flex>
            {props.onClose && (
                <Flex justifyContent="center">
                    <button style={{ marginLeft: 40 }} onClick={props.onClose}>
                        <Icon icon="close" color="gray100" />
                    </button>
                </Flex>
            )}
        </Flex>
    )
}
