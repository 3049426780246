import { postPhoneOrder } from "../../client"
import { useCheckoutContext } from "./CheckoutContext"
import { AdyenComponent } from "../../../../../packages/adyen/web/AdyenComponent"
import { useEffect, useState } from "react"

export type PaymentStepProps = {}
export function PaymentStep() {
    const context = useCheckoutContext()
    const [error, setError] = useState<string | undefined>()

    async function createOrder() {
        try {
            const order = await postPhoneOrder({
                phone: context.offer,
                shippingOption: context.shippingOption!,
                tradeIn: context.tradeIn,
            })
            context.setOrderId(order.id)
        } catch (e: any) {
            if ("detail" in e) {
                setError(e.detail)
            } else if ("message" in e) {
                setError(e.message)
            }
        }
    }
    useEffect(() => {
        void createOrder()
    }, [JSON.stringify(context.offer), JSON.stringify(context.shippingOption)])

    return (
        <div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {context.orderId && (
                <AdyenComponent
                    enableStoreDetails={true}
                    onSuccess={() => context.setStep("Confirmation")}
                    adyenOrder="phone-subscription"
                    adyenOrderId={
                        // The Adyen Order ID is encoded as
                        // `${orderId}_${periodIndex}`. We are paying for the
                        // first period (0).
                        `${context.orderId.valueOf()}_0`
                    }
                />
            )}
            {!context.orderId && !error && <p>Reserving your order...</p>}
        </div>
    )
}
