import { createContext, ReactNode, RefObject, useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { css } from "../../helpers/css"
import { springAnimations } from "../../constants/animation"
import { Component } from "../../../../../../packages/editing/Component"
import { AnimatePresence, motion, useAnimate } from "framer-motion"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Icon, IconName } from "../visual/Icon"
import { Heading } from "../typography/Heading"
import { Flex } from "../base/Flex"
import { Button } from "../buttons/Button"
import { lipsum } from "../../helpers/lipsum"
import { Body } from "../typography/Body"
import { colors } from "../../constants/colors"
import { useLocalize } from "../../../../../../packages/localization/client-side/useLocalize"
import { Text } from "../typography/Text"
import { percentageHexValues } from "../../constants/opacity"

type StepTransition = "next" | "prev" | undefined

type Step = {
    /**
     * Useful for linking directly to a step.
     */
    id?: string

    /**
     * Show the back button on this step.
     */
    backButton?: boolean

    /**
     * @reflection any
     */
    render: (next: () => void, prev: () => void) => ReactNode
}

export function Modal(props: {
    size?: "md" | "lg"
    width?: number | string
    isOpen?: boolean
    onClose?: () => void
    onBack?: () => void
    onOpenAnimationEnd?: () => void
    onCloseAnimationEnd?: () => void

    /**
     * Texts for prompt to show if user attempts to close modal.
     */
    closePrompt?: {
        heading: string
        text: string
        cancelButtonText: string
        confirmButtonText: string
    }

    /**
     * Callback for when step changes.
     */
    onStepChange?: (id: string) => void

    header?: {
        title?: string
        closeButton?: boolean
        backButton?: boolean
        icon?: IconName
    }

    /**
     * Utilize built in steps management in the modal.
     */
    steps?: Step[]

    /**
     * Render function to use in combination with steps that allows for combining step content and
     * non step content, and also provides setStep function.
     * @reflection any
     */
    renderSteps?: (
        children: ReactNode,
        steps: ReactNode,
        setStep: (index: number) => void
    ) => ReactNode

    /**
     * @reflection any
     */
    children?: ReactNode
}) {
    const size = props.size ?? "md"
    const localize = useLocalize()

    const [currentStep, setCurrentStep] = useState(0)
    const [stepTransition, setStepTransition] = useState<"next" | "prev" | undefined>()
    const [promptClose, setPromptClose] = useState(false)

    const handlePrevStep = useCallback(() => {
        setStepTransition("prev")
        setCurrentStep(currentStep - 1)
    }, [setStepTransition, setCurrentStep, currentStep])

    const handleNextStep = useCallback(() => {
        setStepTransition("next")
        setCurrentStep(currentStep + 1)
    }, [setCurrentStep, currentStep, setStepTransition])

    const handleBack = useCallback(() => {
        handlePrevStep()
        if (typeof props.onBack === "function") props.onBack()
    }, [props, handlePrevStep])

    const handleClose = useCallback(() => {
        if (props.closePrompt && !promptClose) setPromptClose(true)
        else props.onClose?.()
    }, [props, promptClose])

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                if (promptClose) setPromptClose(false)
                else handleClose()
            }
        },
        [promptClose, handleClose]
    )

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (props.isOpen) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = ""
            }
        }
    }, [props.isOpen])

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("keydown", handleKeydown)
            return () => {
                window.removeEventListener("keydown", handleKeydown)
            }
        }
    }, [handleKeydown])

    useEffect(() => {
        if (!props.isOpen) {
            setPromptClose(false)
        }
    }, [props.isOpen])

    const [scrollContainerRef, scrollContainerAnimate] = useAnimate()

    if (typeof window === "undefined") return <></>

    return createPortal(
        <ModalContext.Provider value={{ scrollContainerRef, scrollContainerAnimate }}>
            <AnimatePresence>
                {props.isOpen ? (
                    <motion.div
                        key="backdrop"
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            zIndex: 100,
                            opacity: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.32)",
                        }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    />
                ) : null}
                {props.isOpen ? (
                    <div
                        key="container"
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 200,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                        }}
                        css={containerCss}
                    >
                        <Flex
                            key="modal"
                            motion={{
                                transition: springAnimations["200"],
                                variants: {
                                    open: { transform: "translateY(0px)", scale: 1, opacity: 1 },
                                    close: { opacity: 0 },
                                },
                                initial: { transform: "translateY(50px)", scale: 0.75, opacity: 0 },
                                animate: "open",
                                exit: "close",
                                onAnimationComplete: (definition) => {
                                    if (definition === "open" && props.onOpenAnimationEnd) {
                                        props.onOpenAnimationEnd()
                                    } else if (definition === "close") {
                                        props.onCloseAnimationEnd?.()
                                    }
                                },
                            }}
                            direction="column"
                            backgroundColor="grayWhite"
                            borderRadius="lg"
                            elevation
                            style={{ width: props.width ?? "auto" }}
                            css={modalCss}
                        >
                            {promptClose && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Flex
                                        direction="column"
                                        gap={24}
                                        style={{ zIndex: 10001, width: "80%" }}
                                        motion={{
                                            initial: { opacity: 0, translateY: "8px" },
                                            animate: { opacity: 1, translateY: "0px" },
                                            transition: springAnimations["200"],
                                        }}
                                    >
                                        <Heading level={2}>{props.closePrompt?.heading}</Heading>
                                        <Text>{props.closePrompt?.text}</Text>
                                        <Flex gap={8} justifyContent="flex-end">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => setPromptClose(false)}
                                            >
                                                {props.closePrompt?.cancelButtonText}
                                            </Button>
                                            <Button variant="dark" size="sm" onClick={handleClose}>
                                                {props.closePrompt?.confirmButtonText}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <motion.div
                                        initial={{ backdropFilter: "blur(20px)", opacity: 0 }}
                                        animate={{
                                            opacity: 1,
                                            backdropFilter: "blur(40px)",
                                            transition: springAnimations["200"],
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: `${colors.grayWhite}${percentageHexValues["50"]}`,
                                            zIndex: 10000,
                                        }}
                                    ></motion.div>
                                </div>
                            )}
                            {props.header ? (
                                <Flex
                                    justifyContent="space-between"
                                    style={
                                        size === "lg"
                                            ? {
                                                  paddingBottom: 24,
                                                  marginBottom: 24,
                                                  borderBottom: `1px solid ${colors.gray200}`,
                                              }
                                            : { marginBottom: 24 }
                                    }
                                >
                                    <AnimatePresence>
                                        {props.header.backButton ??
                                        props.steps?.[currentStep]?.backButton ? (
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <Button
                                                    key="backButton"
                                                    iconStart="arrowLeft"
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={handleBack}
                                                    margin={{ right: scaleValue(40) }}
                                                    aria-label={localize({
                                                        no: "Tilbake",
                                                        en: "Back",
                                                    })}
                                                />
                                            </motion.div>
                                        ) : null}
                                    </AnimatePresence>
                                    <Flex alignItems="center">
                                        {props.header.icon ? (
                                            <Icon
                                                icon={props.header.icon}
                                                size={[24, ["min", "md", 32]]}
                                                css={css(
                                                    { marginRight: 8 },
                                                    responsiveCss("min", "md", { marginRight: 12 })
                                                )}
                                            />
                                        ) : null}
                                        {props.header.title ? (
                                            <Heading level={2}>{props.header.title}</Heading>
                                        ) : null}
                                    </Flex>
                                    <AnimatePresence>
                                        {props.header.closeButton ? (
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <Button
                                                    key="closeButton"
                                                    iconStart="close"
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={handleClose}
                                                    margin={{ left: scaleValue(40) }}
                                                    aria-label={localize({
                                                        no: "Lukk denne popupen",
                                                        en: "Close this popup",
                                                    })}
                                                />
                                            </motion.div>
                                        ) : null}
                                    </AnimatePresence>
                                </Flex>
                            ) : null}
                            <div
                                style={{
                                    overflowY: props.steps ? "hidden" : "auto",
                                    maxHeight: "100%",
                                    flex: "1 1 auto",
                                    display: "flex",
                                    ...(props.steps
                                        ? {
                                              width: "calc(100% + calc(var(--modal-padding) * 2))",
                                              transform:
                                                  "translateX(calc(var(--modal-padding) * -1))",
                                          }
                                        : {}),
                                }}
                                ref={props.steps ? null : scrollContainerRef}
                            >
                                {props.steps && props.renderSteps ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            paddingLeft: "var(--modal-padding)",
                                            paddingRight: "var(--modal-padding)",
                                        }}
                                    >
                                        {props.renderSteps(
                                            <AnimatePresence
                                                initial={false}
                                                mode="popLayout"
                                                custom={stepTransition}
                                            >
                                                <motion.div
                                                    key={`step${currentStep}`}
                                                    initial="initial"
                                                    animate="target"
                                                    exit="exit"
                                                    style={{
                                                        flex: "1 0 100%",
                                                    }}
                                                    variants={{
                                                        initial: (st: StepTransition) =>
                                                            !st
                                                                ? {}
                                                                : {
                                                                      opacity: 0,
                                                                      translateX:
                                                                          st === "next"
                                                                              ? "100px"
                                                                              : "-100px",
                                                                  },
                                                        target: (st: StepTransition) =>
                                                            !st
                                                                ? {}
                                                                : {
                                                                      opacity: 1,
                                                                      translateX: "0px",
                                                                  },
                                                        exit: (st: StepTransition) =>
                                                            st
                                                                ? {
                                                                      translateX:
                                                                          st === "next"
                                                                              ? "-100px"
                                                                              : "100px",
                                                                      opacity: 0,
                                                                  }
                                                                : {},
                                                    }}
                                                    custom={stepTransition}
                                                >
                                                    <div
                                                        ref={scrollContainerRef}
                                                        style={{
                                                            overflowY: "scroll",
                                                            maxHeight: "100%",
                                                            overflowX: "visible",
                                                        }}
                                                        css={responsiveCss("min", "md", {
                                                            paddingRight: 20,
                                                        })}
                                                    >
                                                        {props.steps[currentStep]?.render(
                                                            handleNextStep,
                                                            handlePrevStep
                                                        )}
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>,
                                            props.children,
                                            setCurrentStep
                                        )}
                                    </div>
                                ) : (
                                    props.children
                                )}
                            </div>
                        </Flex>
                    </div>
                ) : (
                    <></>
                )}
            </AnimatePresence>
        </ModalContext.Provider>,
        document.body
    )
}

const containerCss = css(
    {
        padding: 16,
    },
    responsiveCss("min", "md", {
        padding: 64,
    })
)

const modalCss = css(
    {
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.grayWhite,
        maxHeight: "100%",
        "--modal-padding": "16px",
        padding: "var(--modal-padding)",
    },
    responsiveCss("min", "sm", {
        "--modal-padding": "24px",
    }),
    responsiveCss("min", "md", {
        maxWidth: 960,
        "--modal-padding": "40px",
    })
)

export const ModalContext = createContext<{
    scrollContainerRef?: RefObject<HTMLDivElement>
    scrollContainerAnimate?: (...args: any) => void
}>({})

Component(Modal, {
    name: "Modal",
    gallery: {
        path: "Modal/Modal",
        items: [
            {
                variants: [
                    {
                        props: (state = { isOpen: true }, setState) => ({
                            isOpen: state.isOpen,
                            onClose: () => setState({ isOpen: false }),
                            header: {
                                title: "Trade-in",
                                icon: "handCoins" as IconName,
                                closeButton: true,
                            },
                            children: (
                                <div>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md">{lipsum(100)} </Body>
                                </div>
                            ),
                        }),
                        render: (cmp, _, setState) => (
                            <div>
                                <button
                                    onClick={() => {
                                        setState({ isOpen: true })
                                    }}
                                >
                                    Open modal
                                </button>
                                {cmp}
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
})
