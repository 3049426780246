import { ForwardedRef, forwardRef, ReactNode, RefObject } from "react"
import { css, FixedVariant, fixedVariantCss, responsiveVariantsCss } from "../../helpers/css"
import { Color } from "../../constants/colors"
import { Text } from "../typography/Text"
import { Flex } from "../base/Flex"

const sizes = {
    md: {
        xs: {
            height: 30,
            paddingLeft: 12,
            paddingRight: 12,
        },
        lg: {
            height: 32,
            paddingLeft: 14,
            paddingRight: 14,
        },
        xl: {
            height: 36 * 0.9,
            paddingLeft: 16 * 0.9,
            paddingRight: 16 * 0.9,
        },
    },
}

export const LabelNotification = forwardRef(function LabelNotification(
    props: {
        color: Color
        children: ReactNode
        size?: FixedVariant<"md">
    },
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <Flex
            ref={ref as ForwardedRef<HTMLDivElement>}
            alignItems="center"
            backgroundColor={props.color}
            color="grayWhite"
            borderRadius="md"
            css={css(
                {
                    display: "inline-flex",
                },
                props.size instanceof Array && props.size[0] === "fixed"
                    ? fixedVariantCss(sizes, props.size)
                    : responsiveVariantsCss(sizes, "md")
            )}
        >
            <Text variant="label" size={props.size ?? "md"}>
                {props.children}
            </Text>
        </Flex>
    )
})
