import { BrowserRouter } from "react-router-dom"
import "../../../packages/ssr/SSR2"
import { Login } from "../../../packages/ssr/Login"
import { defaults } from "./client"
import { defaults as studioDefaults } from "../../../studio/client"
import { InitWebApp } from "../../../packages/ssr/InitWebApp"
import { App } from "./App"
import "./Studio"

InitWebApp(() => (
    <Login
        always={false}
        tokenReady={(token) => {
            defaults.headers.Authorization = `Bearer ${token}`
            studioDefaults.headers.Authorization = `Bearer ${token}`
        }}
        clearTokens={() => {
            defaults.headers.Authorization = ""
            studioDefaults.headers.Authorization = ""
        }}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Login>
))
