import { Dispatch, PropsWithChildren, useCallback, useEffect, useReducer, useState } from "react"
import { useCountries, useShippingOptions } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { TextInput } from "../../ui/components/controllers/TextInput"
import { RadioButton } from "../../ui/components/controllers/Radio"
import { useVippsMe } from "../../../../../packages/oidc/code-flow/VippsLogin"
import { Checkbox } from "../../ui/components/controllers/Checkbox"
import { Button } from "../../ui/components/buttons/Button"
import { useCheckoutContext } from "./CheckoutContext"
import { css, responsiveCss } from "../../ui/helpers/css"
import { Flex } from "../../ui/components/base/Flex"
import { colors } from "../../ui/constants/colors"
import { Email, Markdown, PhoneNumber } from "../../../../../reactor"
import { Localized } from "../../../../../packages/localization/Localized"
import { Icon, IconName } from "../../ui/components/visual/Icon"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { MarkdownView } from "../../../../../packages/markdown-edit/MarkdownView"
import { Select } from "../../ui/components/inputs/Select"

type ContactInfo = {
    name?: {
        /**
         * @default '{"en":"Name"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    email?: {
        /**
         * @default '{"en":"Email"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "email"
    }
    phoneNumber?: {
        /**
         * @default '{"en":"Phone"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "tel"
    }
}

type Address = {
    street?: {
        /**
         * @default '{"en":"Street"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }

    unit?: {
        /**
         * @default '{"en":"Unit"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    region?: {
        /**
         * @default '{"en":"Region"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
    postalCode?: {
        /**
         * @default '{"en":"Postal Code"}'
         */
        label: Localized<string>
        group?: number
        order?: number
    }
}

type FormKey = keyof ContactInfo | keyof Address | "countryCode"

type FormField = {
    label: Localized<string>
    formKey: FormKey
    group: number
    order: number
    type: "tel" | "email"
}

type NextSteps = {
    text: Markdown
    icon: IconName
}
type State = Record<FormKey | "countryCode", { value: string; valid?: boolean; dirty?: boolean }>

type Action =
    | { type: keyof State; payload: string }
    | { type: "SET_ALL"; payload: Record<keyof State, string> }

const initialState: State = {
    name: { value: "" },
    email: { value: "" },
    phoneNumber: { value: "" },
    street: { value: "" },
    unit: { value: "" },
    region: { value: "" },
    postalCode: { value: "" },
    countryCode: { value: "" },
}

function formReducer(state: State, action: Action): State {
    if (action.type === "email") {
        try {
            Email(action.payload)
            return { ...state, email: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, email: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type === "phoneNumber") {
        try {
            PhoneNumber(action.payload)
            return { ...state, phoneNumber: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, phoneNumber: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type !== "SET_ALL") {
        return {
            ...state,
            [action.type]: { value: action.payload, dirty: true, valid: !!action.payload },
        }
    } else {
        return {
            ...state,
            ...Object.keys(action.payload).reduce((acc, key) => {
                acc[key as FormKey] = {
                    value: action.payload[key as FormKey],
                    valid: true,
                    dirty: true,
                }
                return acc
            }, {} as Partial<State>),
        }
    }
}

export type ShippingStepProps = {
    /**
     * @default '{"en": "Shipping"}'
     */
    pageHeading: Localized<string>
    /**
     * @default '{"en": "Personal information", "no": "Personopplysninger"}'
     */
    personalInformationHeading: Localized<string>
    /**
     * @default '{"en": "Delivery address", "no": "Leveringsadresse"}'
     */
    addressHeading: Localized<string>
    /**
     * @default '{"en": "Delivery options", "no": "Leveringsalternativer"}'
     */
    deliveryOptionsHeading: Localized<string>
    /**
     * @default '{"en": "No available times", "no": "Ingen tilgjengelige tider"}
     */
    noDeliveryOptions: Localized<string>
    marketingCheckbox: Localized<Markdown>
    termsCheckbox: Localized<Markdown>

    /**
     * @default '{"en": "Proceed to payment", "no": "Gå til betaling"}'
     */
    nextStep: Localized<string>
    contactInfo: ContactInfo
    address: Address
    nextSteps: NextSteps[]
}
export function ShippingStep({
    contactInfo: c,
    address: a,
    nextSteps,
    pageHeading,
    personalInformationHeading,
    addressHeading,
    deliveryOptionsHeading,
    noDeliveryOptions,
    marketingCheckbox,
    termsCheckbox,
    nextStep,
}: ShippingStepProps) {
    const shippingOptions = useShippingOptions("NO", "0015")
    const me = useVippsMe()
    const localize = useLocalize()
    const { setStep, shippingOption, setShippingOption } = useCheckoutContext()
    const { data: countries } = useCountries()

    const [state, dispatch] = useReducer(formReducer, initialState)
    const validate = useCallback(
        () =>
            !Object.keys(state).some(
                (key) => !state[key as FormKey].valid || !state[key as FormKey].dirty
            ),
        [state]
    )

    if (!c || !a) {
        return <div>Shipping step not configured</div>
    }

    const contactInfo = groupAndSort(c)
    const address = groupAndSort(a)

    useEffect(() => {
        if (!me) return
        const [street, unit] = me.address?.street_address?.split("\n") ?? []

        dispatch({
            type: "SET_ALL",
            payload: {
                name: me.name ?? "",
                email: me.email?.valueOf() ?? "",
                phoneNumber: me.phone_number
                    ? me.phone_number.startsWith("+")
                        ? me.phone_number
                        : "+" + me.phone_number
                    : "",
                street: street ?? "",
                unit: unit ?? "",
                countryCode: me.address?.country ?? "",
                region: me.address?.region ?? "",
                postalCode: me.address?.postal_code ?? "",
            },
        })
    }, [JSON.stringify(me)])

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [marketingAccepted, setMarketingAccepted] = useState<boolean>(false)

    return (
        <div>
            <Flex
                direction="column"
                css={css(
                    responsiveCss("min", "md", { padding: 80, gap: 32 }),
                    responsiveCss("max", "sm", { gap: 32 })
                )}
            >
                <span css={css(responsiveCss("max", "sm", { display: "none" }))}>
                    <Heading level={2}>{localize(pageHeading)}</Heading>
                </span>
                <SectionHeading title={localize(personalInformationHeading)}>
                    <Flex direction="column" gap={8}>
                        {contactInfo.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        type={g.type}
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                        <Select
                            value={state.countryCode.value}
                            options={
                                countries?.map((c) => ({
                                    value: c.code,
                                    text: `${c.flag} ${c.name}`,
                                })) ?? []
                            }
                            onChange={(e) => {
                                dispatch({
                                    type: "countryCode",
                                    payload: e.target.value,
                                })
                            }}
                            state={setFormFieldState(state, "countryCode")}
                        ></Select>
                    </Flex>
                </SectionHeading>

                <SectionHeading title={localize(deliveryOptionsHeading)}>
                    <Flex
                        borderRadius="md"
                        direction="column"
                        css={css(
                            responsiveCss("max", "sm", {
                                padding: "8px 0",
                            }),
                            responsiveCss("min", "md", {
                                padding: 40,
                                background: colors.gray100,
                            })
                        )}
                    >
                        {shippingOptions.data?.options.map((option, index) => (
                            <Flex
                                direction="column"
                                padding={
                                    index !== shippingOptions.data!.options.length - 1
                                        ? { bottom: 24 }
                                        : undefined
                                }
                                style={{
                                    borderBottom:
                                        index !== shippingOptions.data!.options.length - 1
                                            ? `1px solid ${colors.gray200}`
                                            : undefined,
                                }}
                                css={css(
                                    responsiveCss("max", "sm", {
                                        gap: 4,
                                    }),
                                    responsiveCss("min", "md", {
                                        gap: 24,
                                    })
                                )}
                            >
                                <Flex
                                    alignItems="center"
                                    css={css(
                                        responsiveCss("max", "sm", {
                                            padding: "8px 0",
                                        })
                                    )}
                                >
                                    <RadioButton checked={true} />
                                    <div
                                        style={{
                                            marginLeft: 16,
                                            color: colors.brand,
                                            fontFamily: "Catalogue",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {option.name}
                                    </div>
                                </Flex>
                                <Flex
                                    gap={8}
                                    css={css(
                                        responsiveCss("max", "sm", {
                                            overflowX: "auto",
                                        })
                                    )}
                                >
                                    {option.slots.map((slot, i) => (
                                        <Button
                                            variant="option"
                                            selected={shippingOption === slot.id}
                                            key={slot.id.valueOf()}
                                            onClick={() => setShippingOption(slot.id)}
                                        >
                                            {slot.date}, {slot.from} - {slot.to}
                                        </Button>
                                    ))}
                                    {option.slots.length === 0 && (
                                        <div style={{ padding: 32 }}>
                                            {localize(noDeliveryOptions)}
                                        </div>
                                    )}
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </SectionHeading>

                <SectionHeading title={localize(addressHeading)}>
                    <Flex direction="column" gap={8}>
                        {address.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        type="email"
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                    </Flex>
                </SectionHeading>

                <SectionHeading title="Next Steps">
                    <Flex
                        direction="column"
                        css={css(
                            responsiveCss("max", "sm", {
                                gap: 8,
                                paddingBottom: 32,
                                borderBottom: `1px solid ${colors.gray200}`,
                            }),
                            responsiveCss("min", "md", {
                                gap: 12,
                                paddingBottom: 32,
                                borderBottom: `1px solid ${colors.gray200}`,
                            })
                        )}
                    >
                        {nextSteps.map((step) => (
                            <Flex
                                borderRadius="md"
                                style={{
                                    background: colors.gray100,
                                    alignItems: "center",
                                }}
                                css={css(
                                    responsiveCss("max", "sm", { padding: 24, fontSize: 15 }),
                                    responsiveCss("min", "md", { padding: "20px 40px" })
                                )}
                            >
                                <Icon icon={step.icon} size={24} style={{ marginRight: 16 }}></Icon>
                                <div css={css(markdownViewP)}>
                                    <MarkdownView value={step.text}></MarkdownView>
                                </div>
                            </Flex>
                        ))}
                    </Flex>
                </SectionHeading>

                <div
                    css={css(
                        responsiveCss("max", "sm", { fontSize: 14 }),
                        responsiveCss("min", "md", { marginTop: -10 })
                    )}
                >
                    <Flex css={containerStyle} style={{ marginBottom: 20 }}>
                        <Flex alignItems="center">
                            <Checkbox
                                checked={marketingAccepted}
                                onChange={(e) => setMarketingAccepted(!marketingAccepted)}
                            />
                        </Flex>
                        <div css={css(markdownViewP)} style={{ marginLeft: 16 }}>
                            <MarkdownView value={localize(marketingCheckbox)}></MarkdownView>
                        </div>
                        <div css={borderStyle} />
                    </Flex>

                    <Flex css={containerStyle}>
                        <Flex alignItems="center">
                            <Checkbox
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                            />
                        </Flex>
                        <div css={css(markdownViewP)} style={{ marginLeft: 16 }}>
                            <div style={{ marginBottom: 0 }}>
                                <MarkdownView value={localize(termsCheckbox)}></MarkdownView>
                            </div>
                        </div>
                        <div css={borderStyle} />
                    </Flex>
                </div>

                <Button
                    variant="primary"
                    onClick={() => setStep("Payment")}
                    disabled={!validate() || !termsAccepted || !shippingOption || !shippingOption}
                >
                    {localize(nextStep)}
                </Button>
            </Flex>
        </div>
    )
}

type SectionHeadingProps = { title: string }
function SectionHeading({ title, children }: PropsWithChildren<SectionHeadingProps>) {
    return (
        <>
            <div css={css(responsiveCss("min", "md", { display: "none" }))}>
                <Heading level={2} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
            <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                <Heading level={3} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
        </>
    )
}

type InputGroupProps = {
    group: FormField[]
    state: State
    localize: ReturnType<typeof useLocalize>
    dispatch: Dispatch<Action>
}
function InputGroup({ group, state, localize, dispatch }: InputGroupProps) {
    return (
        <Flex gap={8} css={css(responsiveCss("max", "sm", { flexDirection: "column" }))}>
            {group.map((field) => (
                <div style={{ flex: 1 }} key={field.formKey}>
                    <TextInput
                        type={field.type || "text"}
                        label={localize(field.label)}
                        value={state[field.formKey].value}
                        onChange={(e) =>
                            dispatch({
                                type: field.formKey,
                                payload: e.target.value,
                            })
                        }
                        state={setFormFieldState(state, field.formKey)}
                    />
                </div>
            ))}
        </Flex>
    )
}

const setFormFieldState = (state: State, key: FormKey) => {
    return state[key].dirty ? (state[key].valid ? "completed" : "error") : undefined
}

function groupAndSort(data: Record<string, Partial<FormField>>) {
    const sortedItems: FormField[] = Object.keys(data)
        .map((key) => {
            const value = data[key]
            if (!value) return
            return {
                formKey: key,
                label: value.label,
                group: value.group ?? 0,
                order: value.order ?? 0,
                type: value.type,
            }
        })
        .filter(Boolean)

        .sort((a, b) => a!.order - b!.order) as FormField[]

    return sortedItems.reduce(
        (acc, item) => {
            if (!item.group) {
                acc.push(item)
            } else {
                const groupIndex = acc.findIndex(
                    (group) => Array.isArray(group) && group[0]?.group === item.group
                )
                if (groupIndex === -1) {
                    acc.push([item])
                } else if (Array.isArray(acc[groupIndex])) {
                    acc[groupIndex]?.push(item)
                }
            }
            return acc
        },
        [] as (FormField | FormField[])[]
    )
}

const markdownViewP = css`
    p {
        margin-bottom: 0;
    }
`
const containerStyle = css`
    position: relative;
    padding-bottom: 20px;
`

const borderStyle = css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid ${colors.gray200};
`
